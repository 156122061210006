import React, { Component } from 'react';

import Layout from "../components/layout";
import SEO from "../components/seo";
import lawDNA from "../images/dna-logo.svg";
import icon1 from "../images/file-alt-regular.svg";
import icon2 from "../images/hand-point-up-regular.svg";
import icon3 from "../images/database-solid.svg";
import icon4 from "../images/tools-solid.svg";
import "../css/lawDNAPage.css";
import icon_line_normal from '../images/icon_line_normal.png';

class LawDNAPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    return (
      <Layout>
        <SEO title="LawDNA" />
        <div className="lawDNA">
          <div className="header-wrap">
            <div className="content-wrap">
              <div className="title">
                法律案件管理系統
              </div>
              <div className="subTitle">卷證電子化閱卷首選</div>
            </div>
            <div className="link-wrap"><a href="https://www.lawdna.com.tw/" className="link start">免費註冊</a></div>
          </div>
          <div className="slogan">
            <div className="txt">
              系統建立目錄檢索、目錄及頁碼生成<br />
              不再印出紙本、助理貼標註明
            </div>
          </div>
          <div className="features">
            <div className="section">
              <div className="title">
                <img src={lawDNA} alt="lawDNA" />
              </div>
              <div className="features-item-wrap">
                <div className="features-item">
                  <div className="icon-wrap">
                    <img src={icon1} alt="案件管理系統" />
                  </div>
                  <div className="title">
                    案件管理系統
                  </div>
                  <div className="txt">
                    LAW DNA 是一套專為法律人而開發的案件管理系統。LAW DNA 提供案件進度、案件相關文件的新增、管理與打包。友善親切的操作介面，清晰地管理各類相關文件，讓您迅速且安全地取得正確文件，讓事務所與律師對於案件內相關知識能夠能夠妥善留存。
                  </div>
                </div>
              </div>
              <div className="features-item-wrap">
                <div className="features-item">
                  <div className="icon-wrap">
                    <img src={icon2} alt="目錄頁碼自動生成" />
                  </div>
                  <div className="title">
                    目錄頁碼自動生成
                  </div>
                  <div className="txt">
                    一旦將複製卷證之電子檔案上傳至 LAW DNA，不需要人力，LAW DNA 生成類似現行科技法庭卷證編目。
                    <ul>
                      <li>單一卷宗目錄與頁碼生成</li>
                      <li>跨卷宗同類別目錄整理</li>
                      <li>跨卷宗同受詢問人筆錄統整</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="features-item-wrap">
                <div className="features-item">
                  <div className="icon-wrap">
                    <img src={icon3} alt="法學資料庫" />
                  </div>
                  <div className="title">
                    法學資料庫
                  </div>
                  <div className="txt">
                    友善親切的操作介面，使法律人能進階並快速的搜尋到與案件相關的法規、裁判書、司法判解、行政函釋等。並能藉由關鍵字、主文、案由、法官等加乘搜尋，更專注地找到相關資料。
                  </div>
                </div>
              </div>
              <div className="features-item-wrap">
                <div className="features-item">
                  <div className="icon-wrap">
                    <img src={icon4} alt="多功能" />
                  </div>
                  <div className="title">
                    多功能
                  </div>
                  <div className="txt">
                    卷證內容全文 OCR 處理文字萃取，LAW DNA 讓法律人在撰寫函文時能更便利。
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="community">
            <a href="https://liff.line.me/1645278921-kWRPP32q?accountId=665xbnci&openerPlatform=native&openerKey=talkroom%3Amessage#mst_challenge=TKFXpBV5QA5sp3oG0arXlkyKXrQ-X5-raEZwJJZF9kY" target="blank"><img src={icon_line_normal} alt="lawdna" /></a>
          </div>
        </div>
      </Layout>
    )
  }
}

export default LawDNAPage;
